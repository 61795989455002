import ReactDOM from 'react-dom'
import React from 'react'
import Home from '../app/home'

ReactDOM.render(<Home />, document.getElementById('root'))

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/sw.js') // path to your bundled service worker with GoldenRetriever service worker
    .then((registration) => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope)
    })
    .catch((error) => {
      console.log('Registration failed with ' + error)
    })
}
