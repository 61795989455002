import IntlProvider from '@little-core-labs/palette-react/components/intl/provider'
import UploadInput from '@little-core-labs/palette-react/components/upload/input'
import UploadForm from '@little-core-labs/palette-react/components/upload/form'
import React from 'react'
import qs from 'query-string'

import '@little-core-labs/palette-css/index.scss'
import './home.scss'

const defaultMetaFields = [{ id: 'title', name: 'Title', placeholder: 'Title' }]
const params = qs.parse(global.location.search)

const allowedHosts = [
  'https://ingestion.mhq.12core.net',
  'https://ingestion.mhq-staging.12core.net',
  'http://localhost:3001',
  'http://localhost:3001',
].concat(params.hosts || [])

export default (props) => (
  <IntlProvider>
    {props.simple || params.simple
        ? <div className="d-flex" style={{ position: 'relative' }}><UploadInput /></div>
        : <UploadForm allowedHosts={allowedHosts} metaFields={defaultMetaFields} features={params.features} uppy={{
          s3: params.s3 ? undefined : false
        }} />
    }
  </IntlProvider>
)
